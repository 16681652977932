



























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import DateTimePicker from '@/components/inputs/DateTimePicker.vue';
import SmsInteractionsMessages from './SmsInteractionsMessages.vue';
import SmsSidebar from './SmsSidebar.vue';
import { Interaction, Patient, Practitioner } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';
import { activeConversation } from '../useConversation';
import { formatISO } from 'date-fns/fp';
import {
  mdiAttachment,
  mdiClockOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiMenu,
  mdiMessageTextOutline,
  mdiMicrophone,
  mdiPhone,
  mdiRefresh,
  mdiSendOutline,
  mdiVideoOutline,
} from '@mdi/js';
import { storage } from '@/main';

export default defineComponent({
  components: { SmsSidebar, SmsInteractionsMessages, DateTimePicker },
  name: 'SmsInteractions',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: false,
    },
  },

  setup() {
    const practitioner = ref<Practitioner>();
    const chatScroll = ref(null);
    const sms = ref<Interaction>(new Interaction());
    const message = ref('');
    const messages = ref<any>(null);
    const sending = ref(false);
    const sendAt = ref(new Date());
    const menu = ref(false);
    const composeMessage = ref<HTMLElement | null>(null);

    onMounted(async () => {
      practitioner.value = (await storage.auth.practitioner()) as Practitioner;
    });

    const scrollToLatest = () => {
      const chat = chatScroll.value as any;

      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    };

    const send = async (): Promise<void> => {
      if (!practitioner.value) {
        return;
      }
      sending.value = true;
      await activeConversation.value?.patient?.smsInteractions().create({
        type: 'sms',
        content: message.value,
        from: practitioner.value.uuid,
        to: activeConversation.value.patient?.uuid,
        sendAt: formatISO(sendAt.value instanceof Date ? sendAt.value : new Date()),
      });

      await messages.value?.loadInteractions();
      sending.value = false;
      sendAt.value = new Date();
      message.value = '';
      scrollToLatest();
    };

    // watch(message, (value: string) => {
    //   sms.value.tempData.content = value;
    // });

    const newPatientConversation = (patient: Patient) => {
      if (patient) {
        //
      }
    };

    const cancelSchedule = () => {
      menu.value = false;
      sendAt.value = new Date();
    };

    return {
      practitioner,
      chatScroll,
      scrollToLatest,
      icons: {
        mdiMenu,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAttachment,
        mdiMicrophone,
        mdiSendOutline,
        mdiClockOutline,
        mdiRefresh,
      },
      sms,
      message,
      send,
      messages,
      activeConversation,
      sending,
      sendAt,
      menu,
      cancelSchedule,
      newPatientConversation,
      composeMessage,
    };
  },
});
