






















import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import SmsSidebarListItem from '../sms-interaction/SmsSidebarListItem.vue';
import { Conversation, Interaction, Patient, Practitioner } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';
import { activeConversation } from '../useConversation';
import { isActiveConversation, setActiveConversation } from '../useConversation';

export default defineComponent({
  components: { SmsSidebarListItem, PatientSelectInput },
  name: 'EmailSidebar',
  props: {
    patient: {
      type: Object as PropType<Patient>,
    },
    practitioner: {
      type: Object as PropType<Practitioner>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const conversations = ref<Conversation[]>([]);
    const loading = ref(false);
    const newPatient = ref<null | Patient>(null);

    const loadConversations = async (): Promise<void> => {
      loading.value = true;
      conversations.value = (await props.practitioner?.emailConversations().get()) ?? [];
      loading.value = false;
    };

    const selectCurrentPatientConversation = () => {
      const { patient } = props;

      if (!patient) {
        return;
      }

      const conversation = conversations.value.find((conversation) => conversation.patient?.uuid === patient.uuid);

      if (conversation) {
        activeConversation.value = conversation;
      } else {
        selectPatient(patient);
      }
    };

    const selectPatient = (patient: Patient) => {
      emit('patientSelect', patient);
      if (patient) {
        let conversation = conversations.value.find(
          (conversation: Conversation) => conversation.data.patient.uuid === patient.uuid
        );
        if (!conversation) {
          conversation = new Conversation({
            uuid: '',
            patient: patient.data,
            practitioner: props.practitioner.data,
            latestInteraction: new Interaction().data,
            latestInteractionTime: '',
          });
        }

        newPatient.value = null;
        setActiveConversation(conversation);
      }
    };

    onMounted(async () => {
      await loadConversations();
      selectCurrentPatientConversation();
    });

    return {
      conversations,
      isActiveConversation,
      setActiveConversation,
      loading,
      selectPatient,
      loadConversations,
      newPatient,
    };
  },
});
