




























/* eslint-disable @typescript-eslint/no-explicit-any */
import ComposeEmail from './ComposeEmail.vue';
import EmailInteractionsList from './EmailInteractionsList.vue';
import EmailSidebar from './EmailSidebar.vue';
import { Interaction, Patient, Practitioner } from '@/models';
import { activeConversation } from '../useConversation';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import {
  mdiAttachment,
  mdiClockOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiMenu,
  mdiMessageTextOutline,
  mdiMicrophone,
  mdiPhone,
  mdiRefresh,
  mdiSendOutline,
  mdiVideoOutline,
} from '@mdi/js';
import { storage } from '@/main';
import { useRouter } from '@/router';

export default defineComponent({
  components: { EmailInteractionsList, EmailSidebar, ComposeEmail },
  name: 'EmailInteractions',

  setup() {
    const practitioner = ref<Practitioner>();
    const chatScroll = ref(null);
    const sms = ref<Interaction>(new Interaction());
    const message = ref('');
    const messages = ref<any>(null);
    const sending = ref(false);
    const sendAt = ref(new Date());
    const menu = ref(false);
    const patient = ref<Patient>();
    const emails = ref<any>();
    const sidebar = ref<any>();

    const { route } = useRouter();

    const composing = ref(false);

    onMounted(async () => {
      practitioner.value = (await storage.auth.practitioner()) as Practitioner;
      patient.value = await Patient.find(route.value.params.uuid);
    });

    const scrollToLatest = () => {
      const chat = chatScroll.value as any;

      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    };

    const loadEmails = async () => {
      composing.value = false;
      await emails.value?.loadInteractions();
      await sidebar.value?.loadConversations();
    };

    const newPatientConversation = (patient: Patient | null) => {
      if (patient) {
        composing.value = true;
      }
    };

    const cancelSchedule = () => {
      menu.value = false;
      sendAt.value = new Date();
    };

    return {
      practitioner,
      chatScroll,
      scrollToLatest,
      icons: {
        mdiMenu,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAttachment,
        mdiMicrophone,
        mdiSendOutline,
        mdiClockOutline,
        mdiRefresh,
      },
      sms,
      message,

      messages,
      activeConversation,
      sending,
      sendAt,
      menu,
      cancelSchedule,
      patient,
      composing,
      emails,
      loadEmails,
      newPatientConversation,
      sidebar,
    };
  },
});
