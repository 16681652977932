














import EmailInteractionListItem from './EmailInteractionListItem.vue';
import { Interaction } from '@/models';
import { activeConversation } from '../useConversation';
import { defineComponent, ref, watch } from '@/plugins/composition';

export default defineComponent({
  components: { EmailInteractionListItem },
  name: 'EmailInteractionsList',

  setup(props, { emit }) {
    const interactions = ref<Interaction[]>([]);
    const loading = ref(false);

    const loadInteractions = async (): Promise<void> => {
      loading.value = true;

      interactions.value = (await activeConversation.value?.patient?.emailInteractions().getAll()) ?? [];

      loading.value = false;
    };

    watch(activeConversation, async () => {
      await loadInteractions();
      emit('loaded');
    });

    return {
      loading,
      interactions,
      loadInteractions,
    };
  },
});
