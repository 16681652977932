

































import { Attachment, Client, Patient } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref, watch } from '@/plugins/composition';

export default defineComponent({
  name: 'AttachmentSelect',
  props: {
    attachable: {
      type: Object as PropType<Patient | Client>,
      required: true,
    },
    value: {
      type: Array as PropType<Attachment[]>,
      required: false,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const attachments = ref<Attachment[]>([]);
    const loading = ref(false);
    const selected = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const loadAttachments = async () => {
      loading.value = true;
      attachments.value = await props.attachable.attachments().getAll();
      loading.value = false;
    };

    onMounted(() => loadAttachments());

    watch(
      () => props.value,
      (value: Attachment[]) => {
        value.forEach((attachment: Attachment) => {
          if (!attachments.value.find((item) => item.data.uuid === attachment.data.uuid)) {
            attachments.value.push(attachment);
          }
        });
      }
    );

    return {
      loading,
      attachments,
      selected,
    };
  },
});
