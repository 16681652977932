


















































































import TextEditor from '@/components/inputs/TextEditor.vue';
import { Attachment, Practitioner } from '@/models';
import { activeConversation } from '../useConversation';
import { computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { formatISO } from 'date-fns';
import { mdiClockOutline } from '@mdi/js';

import AttachmentSelect from '@/components/inputs/AttachmentSelect.vue';
import DateTimePicker from '@/components/inputs/DateTimePicker.vue';
import UploadAttachmentsDialog from '@/domains/practitioner/modules/patients/components/UploadAttachmentsDialog.vue';
import { storage } from '@/main';

export default defineComponent({
  components: { TextEditor, AttachmentSelect, UploadAttachmentsDialog, DateTimePicker },
  name: 'ComposeEmail',

  setup(props, { emit }) {
    const content = ref('');

    const sending = ref(false);
    const practitioner = ref<Practitioner>();
    const sendAt = ref(new Date());
    const attaching = ref(false);
    const subject = ref('New Message from HPWHI');
    const attachDialog = ref(false);
    const attachments = ref<Attachment[]>([]);
    const menu = ref(false);

    onMounted(async () => {
      practitioner.value = (await storage.auth.practitioner()) as Practitioner;
    });

    const to = computed(() => ({
      name: activeConversation.value?.patient?.name,
      email: activeConversation.value?.patient?.data.email,
    }));

    const send = async (): Promise<void> => {
      if (!practitioner.value) {
        return;
      }
      sending.value = true;
      await activeConversation.value?.patient?.emailInteractions().create({
        type: 'email',
        subject: subject.value,
        content: content.value,
        from: practitioner.value.uuid,
        to: activeConversation.value.patient?.uuid,
        sendAt: formatISO(sendAt.value instanceof Date ? sendAt.value : new Date()),
        attachments: attachments.value.map((item: Attachment) => item.data.uuid),
      });

      sending.value = false;
      sendAt.value = new Date();
      content.value = '';
      emit('sent');
    };

    const attachFiles = () => {
      attaching.value = true;
    };

    const setAttachments = (items: Attachment[]) => {
      attachments.value.push(...items);
    };

    const cancelSchedule = () => {
      menu.value = false;
      sendAt.value = new Date();
    };

    return {
      content,
      send,
      attachFiles,
      activeConversation,
      subject,
      to,
      attachDialog,
      attachments,
      menu,
      sendAt,
      setAttachments,
      icons: {
        mdiClockOutline,
      },
      cancelSchedule,
    };
  },
});
