var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column text-truncate pa-4 white"},[_c('span',[_vm._v(_vm._s(_vm.to.name))]),_c('span',{staticClass:"text-body-2 font-weight-light"},[_vm._v(_vm._s(_vm.to.email))])]),_c('v-divider'),_c('div',[_c('v-text-field',{attrs:{"solo":"","flat":"","hide-details":"","label":"Subject","persistent-placeholder":"","placeholder":"Subject"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('text-editor',{attrs:{"flat":"","max-height":"300"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-toolbar',{staticStyle:{"border-top":"thin solid rgba(0, 0, 0, 0.12)"},attrs:{"dense":"","flat":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":_vm.send}},[_vm._v("Send")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('v-spacer'),_vm._l((_vm.attachments),function(attachment){return _c('v-chip',{key:attachment.data.uuid,staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(attachment.data.filename))])}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","top":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClockOutline)+" ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"pa-2 white"},[_c('date-time-picker',{attrs:{"min":new Date()},model:{value:(_vm.sendAt),callback:function ($$v) {_vm.sendAt=$$v},expression:"sendAt"}}),_c('div',{staticClass:"d-flex pt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.cancelSchedule}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" OK ")])],1)],1)]),_c('v-dialog',{attrs:{"width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.attachFiles}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}]),model:{value:(_vm.attachDialog),callback:function ($$v) {_vm.attachDialog=$$v},expression:"attachDialog"}},[(_vm.activeConversation && _vm.activeConversation.patient)?_c('v-card',[_c('v-card-title',[_vm._v("Add Attachments")]),_c('v-card-text',[_c('div',[_c('attachment-select',{attrs:{"attachable":_vm.activeConversation.patient},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}})],1)]),_c('v-card-actions',[_c('upload-attachments-dialog',{attrs:{"attachable":_vm.activeConversation.patient},on:{"input":_vm.setAttachments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"color":"info"}},'v-btn',attrs,false),on),[_vm._v("Upload")])]}}],null,false,340903554)}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.attachDialog = false}}},[_vm._v("Done")])],1)],1):_vm._e()],1)],2)]},proxy:true}]),model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }