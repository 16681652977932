










import SmsInteractions from './SmsInteractions.vue';
import { Patient } from '@/models';
import { PropType, computed, defineComponent } from '@/plugins/composition';

export default defineComponent({
  components: { SmsInteractions },
  name: 'SmsInteractionsDialog',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    return {
      dialog,
    };
  },
});
