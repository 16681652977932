import { Conversation } from '@/models';
import { ref } from '@/plugins/composition';

const activeConversation = ref<Conversation | null>(null);

const isActiveConversation = (conversation: Conversation): boolean => {
  return conversation.uuid === activeConversation.value?.uuid;
};

const setActiveConversation = (conversation: Conversation): void => {
  activeConversation.value = conversation;
};

const resetActiveConversation = (): void => {
  activeConversation.value = null;
};

export { activeConversation, setActiveConversation, isActiveConversation, resetActiveConversation };
