





















/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Blockquote,
  Bold,
  BulletList,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from 'tiptap-vuetify';
import { computed, defineComponent, onMounted } from '@/plugins/composition';

export default defineComponent({
  name: 'TextEditor',
  components: {
    TiptapVuetify,
  },
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    maxHeight: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    const content = computed({
      get: () => props.value,
      set: (value: string) => emit('input', value),
    });

    const init = (e: any) => {
      e.editor.focus();
    };

    const scrollToBottom = () => {
      // const text = document.getElementsByClassName('ProseMirror')[0];
    };

    onMounted(() => scrollToBottom());

    const extensions = [
      Bold,
      Italic,
      Strike,
      Underline,
      Paragraph,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      ListItem,
      Link,
      Blockquote,
      HardBreak,
      HorizontalRule,
      History,
    ];

    return {
      content,
      extensions,
      init,
    };
  },
});
