






















import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import SmsSidebarListItem from './SmsSidebarListItem.vue';
import { Conversation, Interaction, Patient, Practitioner } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';
import { activeConversation } from '../useConversation';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  components: { SmsSidebarListItem, PatientSelectInput },
  name: 'SmsSidebar',
  props: {
    practitioner: {
      type: Object as PropType<Practitioner>,
      required: true,
    },
    patient: {
      type: Object as PropType<Patient>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const conversations = ref<Conversation[]>([]);
    const newPatient = ref<null | Patient>(null);

    const { loading, withLoader } = useLoader({ initial: true });

    const loadConversations = async () =>
      withLoader(async () => {
        conversations.value = await props.practitioner.smsConverations().get();
      });

    const isActiveConversation = (conversation: Conversation) => {
      return conversation.uuid === activeConversation.value?.uuid;
    };

    const setActiveConversation = (conversation: Conversation) => {
      activeConversation.value = conversation;
    };

    const selectCurrentPatientConversation = () => {
      const { patient } = props;

      if (!patient) {
        return;
      }

      const conversation = conversations.value.find((conversation) => conversation.patient?.uuid === patient.uuid);

      if (conversation) {
        activeConversation.value = conversation;
      } else {
        selectPatient(patient);
      }
    };

    onMounted(async () => {
      await loadConversations();
      selectCurrentPatientConversation();
    });

    const selectPatient = (patient: Patient) => {
      emit('patientSelect', patient);
      if (patient) {
        let conversation = conversations.value.find(
          (conversation: Conversation) => conversation.data.patient.uuid === patient.uuid
        );
        if (!conversation) {
          conversation = new Conversation({
            uuid: '',
            patient: patient.data,
            practitioner: props.practitioner.data,
            latestInteraction: new Interaction().data,
            latestInteractionTime: '',
          });
        }

        // nextTick(() => {
        //   newPatient.value = null;
        // });
        setActiveConversation(conversation);
      }
    };

    return {
      conversations,
      activeConversation,
      isActiveConversation,
      setActiveConversation,
      loading,
      selectPatient,
      newPatient,
    };
  },
});
