








import PatientMenu from '../../components/PatientMenu.vue';
import { Patient } from '@/models';
import { SidebarLayout } from '../../../shared';
import { activeTab } from './activeTab';
import { defineComponent, onMounted, ref } from 'vue-demi';
import { useRouter } from '@/router/router';

export default defineComponent({
  name: 'ViewPatientsPage',
  components: {
    PatientMenu,
    SidebarLayout,
  },
  props: {
    sms: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const drawer = ref(false);
    const patient = ref<Patient | null>(null);

    const { route } = useRouter();

    const getPatient = async (): Promise<void> => {
      const uuid = route.value.params.uuid;
      patient.value = await Patient.find(uuid);
    };

    onMounted(async () => await getPatient());

    return {
      drawer,
      activeTab,
      patient,
    };
  },
});
