























































import { Interaction, User } from '@/models';
import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { formatDateTime } from '@/utils/date';
import { mdiCheck, mdiCheckAll, mdiClose } from '@mdi/js';
import { storage } from '@/main';

export default defineComponent({
  name: 'EmailInteractionListItem',
  props: {
    interaction: {
      type: Object as PropType<Interaction>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const me = computed(() => storage.auth.state.user as User);
    const viewing = ref(false);
    const loading = ref(false);
    const previewHtml = ref('');
    const email = ref<Interaction>();

    const loadPreview = async () => {
      loading.value = true;
      email.value = await Interaction.find(props.interaction.uuid);
      loading.value = false;
    };

    const destroy = async (interaction: Interaction): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this message?', {
        confirmType: 'warn',
      });

      if (confirm) {
        interaction.directDelete();

        emit('deleted');
      }
    };

    watch(viewing, async () => await loadPreview());

    return {
      me,
      emit,
      loading,
      viewing,
      previewHtml,
      destroy,
      formatDateTime,
      email,
      mdiCheck,
      mdiCheckAll,
      mdiClose,
    };
  },
});
