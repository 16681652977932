




























































import { Interaction, Practitioner } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref, watch } from '@/plugins/composition';
import { activeConversation } from '../useConversation';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { mdiCheck, mdiCheckAll, mdiClose } from '@mdi/js';
import { storage } from '@/main';
import { useEcho } from '@/plugins/echo';

export default defineComponent({
  name: 'SmsInteractionMessages',
  props: {
    practitioner: {
      type: Object as PropType<Practitioner>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const interactions = ref<Interaction[]>([]);
    const { echo } = useEcho();

    echo
      .private(`interactions.${props.practitioner.uuid}`)
      .listen('.interaction.sent', async () => {
        await loadInteractions();
      })
      .listen('.interaction.inbound', async () => {
        await loadInteractions();
      })
      .listen('.interaction.marked-as-read', async () => {
        await loadInteractions();
      })
      .listen('.interaction.delivered', async () => {
        await loadInteractions();
      });

    const loadInteractions = async (): Promise<void> => {
      interactions.value = (await activeConversation.value?.patient?.smsInteractions().getAll()) ?? [];
      emit('refreshed');
    };

    const hasUnread = computed(() => {
      return interactions.value.some((interaction: Interaction) => {
        return interaction.isUnread;
      });
    });

    onMounted(async () => await loadInteractions());

    watch(activeConversation, async () => {
      await loadInteractions();
      emit('loaded');
    });

    const markAllAsRead = async () => {
      await activeConversation.value?.markAlAsInteractionsRead();
    };

    const destroy = async (interaction: Interaction): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this message?', {
        confirmType: 'warn',
      });

      if (confirm) {
        interaction.directDelete();
        await loadInteractions();
        emit('input');
      }
    };

    return {
      mdiCheck,
      mdiCheckAll,
      mdiClose,
      storage,
      interactions,
      destroy,
      loadInteractions,
      activeConversation,
      hasUnread,
      markAllAsRead,
    };
  },
});
