










import EmailInteractions from './EmailInteractions.vue';
import { defineComponent, ref, watch } from '@/plugins/composition';
import { resetActiveConversation } from '../useConversation';

export default defineComponent({
  components: { EmailInteractions },
  name: 'EmailInteractionsDialog',

  setup() {
    const dialog = ref(false);

    watch(dialog, (value: boolean) => {
      if (!value) {
        resetActiveConversation();
      }
    });

    return {
      dialog,
    };
  },
});
