




























import { Conversation, User } from '@/models';
import { PropType, computed, defineComponent } from '@/plugins/composition';
import { storage } from '@/main';

export default defineComponent({
  name: 'SmsSidebarListItem',
  props: {
    conversation: {
      type: Object as PropType<Conversation>,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props, { emit }) {
    const me = computed(() => storage.auth.state.user as User);
    return {
      me,
      emit,
    };
  },
});
