






































































import AvatarInput from '@/components/inputs/AvatarInput.vue';
import EmailInteractionsDialog from '@/components/interactions/email-interaction/EmailInteractionsDialog.vue';
import PractitionerSelectInput from '@/components/inputs/PractitionerSelectInput.vue';
import QuickNote from '../../bookings/components/QuickNote.vue';
import SmsInteractionsDialog from '@/components/interactions/sms-interaction/SmsInteractionsDialog.vue';
import { Patient, Practitioner } from '@/models';
import { PropType, defineComponent, ref, watch } from 'vue-demi';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  components: { SmsInteractionsDialog, EmailInteractionsDialog, QuickNote, AvatarInput, PractitionerSelectInput },
  name: 'PatientMenu',
  props: {
    user: {
      type: Object as PropType<Patient>,
      required: true,
    },
    sms: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tabs = [
      { icon: 'mdi-account', text: 'Profile', to: routeNames.practitioner.patients.view.profile },
      { icon: 'mdi-account', text: 'Bookings', to: routeNames.practitioner.patients.view.bookings },
      { icon: 'mdi-paperclip', text: 'Attachments', to: routeNames.practitioner.patients.view.attachments },
      {
        icon: 'mdi-chart-areaspline-variant',
        text: 'Assessments',
        to: routeNames.practitioner.patients.view.assessments,
      },
      // { icon: 'mdi-message-processing', text: 'Messages' },
      // { icon: 'mdi-message-processing', text: 'Messages' },
      // { icon: 'mdi-email-plus', text: 'Emails' },
      // { icon: 'mdi-chart-areaspline-variant', text: 'Assessments' },
    ];

    const showSms = ref(false);

    const { router } = useRouter();

    const destroy = async (): Promise<void> => {
      const confirm = await confirmation(`Are you sure you want to delete ${props.user.data.name}?`, {
        confirmText: 'Delete',
        cancelText: 'Cancel',
        confirmType: 'warn',
      });

      if (confirm) {
        props.user.delete();
        router.push({ name: routeNames.practitioner.patients.all });
      }
    };

    const updatePrimaryPractitioner = async (practitioner: Practitioner) => {
      const response = await props.user.update({
        primaryPractitioner: practitioner.serialise(),
      });
    };

    watch(
      () => props.sms,
      (value: boolean) => {
        if (value) {
          showSms.value = true;
        }
      },
      { immediate: true }
    );

    watch(showSms, (value: boolean) => {
      if (!value) {
        router.push({ name: routeNames.practitioner.patients.view.profile });
      }
    });

    return {
      tabs,
      destroy,
      updatePrimaryPractitioner,
      showSms,
    };
  },
});
